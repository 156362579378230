
export const environment = {
  production: true,
  version: '{BUILD_VERSION}',
  buildDate: '{BUILD_DATE}',

  base_url: 'https://api-dev.smemove.com',
  api_url: 'https://api-dev.smemove.com/api',
  client_url: 'https://console-dev.smemove.com',
  client_id: '1',
  client_secret: 'vVvbN7gs7UgxrP4E8kOvMDxbzGehowrZ120PzVl1',
  smemove_corp_id: 2,
}
